<template>
  <v-app @keyup.enter="resetPwd">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row justify="center" align="center">
          <v-col v-if="dialog" cols="10" sm="8" md="4" lg="3">
            <v-card class="elevation-12">
              <v-card-title class="primary white--text"
                >SAC-Tourenverwaltung <br />Passwort zurücksetzten
              </v-card-title>
              <v-card-text>
                <v-text-field
                  v-if="!success"
                  name="email"
                  v-model="email"
                  required
                  label="E-Mail"
                  ref="email"
                  tabindex="1"
                  autofocus
                  :error-messages="[error]"
                />
                <p v-else>
                  Eine Email mit der Anleitung zum zurücksetzten des Passwort
                  wurde an
                  <b>{{ email }}</b> gesendet.
                </p>
                <v-btn
                  success
                  block
                  tabindex="3"
                  color="success"
                  @click="resetPwd"
                  :loading="btnLoading"
                  :disabled="btnDisabled"
                >
                  Passwort zurücksetzten
                </v-btn>
              </v-card-text>
              <v-card-text>
                <router-link :to="{ name: 'Login' }">
                  Zurück zum Login
                </router-link>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import authService from '@/services/auth'

export default {
  name: 'PWDResetInit',
  components: {},
  props: {},
  data() {
    return {
      email: '',
      error: '',
      dialog: true,
      submitted: false,
      success: false,
    }
  },
  computed: {
    dataEntered() {
      return this.email !== ''
    },
    btnDisabled() {
      return this.success || this.submitted
    },
    btnLoading() {
      return this.submitted && !this.success
    },
  },
  methods: {
    resetPwd() {
      this.submitted = true
      this.error = ''
      authService
        .pwdresetInit(this.email)
        .then(response => {
          this.success = true
        })
        .catch(error => {
          const status = error.response.status
          if (status === 404) {
            this.error = 'Kein aktiver Login mit dieser Email gefunden.'
          }
          this.success = false
          this.submitted = false
        })
    },
  },
}
</script>

<style scoped>
.card__title {
  display: block;
  text-align: center;
  font-size: 1.4em;
}
</style>
